<!-- System: STA
    Purpose: This compoment will display intelligent
            reports section.
-->
<template>
  <div>
    <v-row class="mt-4 d-flex justify-end">
      <!-- Date Range Picker -->
      <v-col cols="12" sm="6" md="2" class="mt-n1">
        <label
          class="labels d-block font-weight-thin text-left ml-4"
          for
          id="cRangei"
          >Date Range:</label
        >
        <v-menu
          id="list-menu-new"
          :close-on-click="false"
          nudge-left="0"
          offset-y
        >
          <template v-slot:activator="{ on, attrs }">
            <v-col class="d-flex flex-row-reverse">
              <v-subheader
                class="selected-date"
                v-bind="attrs"
                v-on="on"
                style="width: 100%"
                @click="checkClick"
              >
                {{ dateRangeTitle }}
                <span class="d-flex justify-start ml-auto">
                  <v-icon
                    color="primary"
                    id="rangeBtni"
                    class="mdi-dark mdi-inactive"
                    >mdi-calendar-range</v-icon
                  >
                </span>
              </v-subheader>
            </v-col>
          </template>
          <v-list id="custom-list-style-i">
            <v-list-item
              style="min-height: 30px"
              v-for="(item, index) in items"
              :key="index"
              @click="checkIfCustomDate(item)"
            >
              <v-list-item-title style="font-size: 13px" class="custom">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-dialog
          ref="dialog"
          v-model="modal_2"
          :return-value.sync="date_2"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-show="(modal_2_show = false)"
              v-model="picker"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="picker" no-title>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="modal_2 = false">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              @click="$refs.dialog.save(picker), selectedDateTeamMembers()"
            >
              OK
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!-- Member Dropdown -->
      <v-col cols="12" sm="12" md="2" lg="2">
        <v-autocomplete
          outlined
          dense
          v-model="selectedMember"
          :items="filteredData"
          :loading="teamMemberLoader"
          item-text="name"
          item-value="id"
          label="Search By Members"
          class="mt-4"
        ></v-autocomplete>
      </v-col>
      <!-- Search Field For Active Window -->
      <v-col cols="12" sm="12" md="2" lg="2">
        <v-text-field
          outlined
          dense
          id="search"
          v-model="selectedWindow"
          ref="search"
          color="primary"
          label="Search Active Windows"
          class="mt-4"
        ></v-text-field>
      </v-col>
      <!-- Export button -->
      <v-col
        cols="6"
        md="2"
        sm="4"
        lg="2"
        class="btn-csv-ir mt-6"
        v-if="
          active_windows_reports_data && active_windows_reports_data.length > 0
        "
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="#2758f6" dark v-bind="attrs" v-on="on" class="mt-n2">
              Export As
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title align="left">
                <download-csv
                  :data="csvExportSetup.data"
                  :fields="csvExportSetup.fields"
                  :labels="csvExportSetup.labels"
                  :name="csvExportSetup.name"
                  >CSV</download-csv
                >
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title align="left">
                <download-excel
                  :data="excelExportSetup.data"
                  :fields="excelExportSetup.fields"
                  :name="excelExportSetup.name"
                  worksheet="Intelligence Report"
                  >Excel</download-excel
                >
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- Search Button -->
      <v-col cols="6" sm="4" md="2" lg="2">
        <v-btn
          primary
          class="active-custom-btn mt-4 px-10 px-sm-16 px-md-5 px-lg-16"
          :disabled="selectedMember == '' ? true : false"
          @click="get_windows_screenshots_diary"
        >
          <span class="mdi">Search</span>
        </v-btn>
      </v-col>
    </v-row>
    <!-- Render If No Data Found -->
    <div
      v-if="
        !active_windows_reports_data || active_windows_reports_data.length === 0
      "
    >
      <div class="mt-4 text-center">
        <img
          class="error-img"
          src="../../assets/images/no-activity-found-new.svg"
        />
        <p
          class="error_text"
          v-if="!selectedMember && selectedCompanyUserStatus !== 'employee'"
        >
          Please select the team member and press search button
        </p>
        <p class="error_text" v-else>No data found</p>
      </div>
    </div>
    <v-row v-else>
      <v-col cols="12" sm="10" md="12" class="mx-auto">
        <div class="mx-10">
          <!-- intelligence Report Table -->
          <v-data-table
            :headers="dessertHeaders"
            :items="active_windows_reports_data"
            :single-expand="singleExpand"
            :expanded.sync="expanded"
            item-key="active_window"
            show-expand
            class="elevation-1"
            hide-default-footer
            disable-pagination
          >
            <template v-slot:item.worked_time="props">
              {{ min_to_hour(props.item.worked_time) }}
            </template>
            <template v-slot:expanded-item="{ headers, item }">
              <td :colspan="headers.length" style="display: flex; height: auto">
                <v-style>
                  .screenshot-{{ item.index }} { max-width: 100%; height:
                  {{ item.height }}px; background-image: url({{
                    url()
                  }}storage/screenshots/thumbnails/{{
                    item.screenshot_token
                  }}/{{ item.filename }}); } @media (max-width: 768px){
                  .screenshot-{{ item.index }}{ width: 75px; } } @media
                  (max-width: 511px){ .screenshot-{{ item.index }}{ width:
                  100px; } }
                </v-style>
                <div
                  class="img-holder window-listing-img-div col-12 col-md-12 row"
                >
                  <v-card
                    :key="i"
                    elevation="10"
                    class="col-img img-area"
                    v-for="(image, i) in item.screenshots"
                  >
                    <a
                      :href="
                        url() +
                        'storage/screenshots/full_size/' +
                        image.screenshot_token +
                        '/' +
                        $route.params.id +
                        '/' +
                        image.project_id +
                        '/' +
                        selectedMember +
                        '/' +
                        image.filename
                      "
                      data-disableScrolling="true"
                      data-lightbox="image-1"
                      v-if="image.filename.length < 60"
                    >
                      <div
                        class="img-area"
                        :class="`screenshot-${item.index}`"
                        :style="{ 'background-position': image.css }"
                      ></div>
                    </a>
                    <a
                      :href="
                        url() +
                        'storage/screenshots/full_size/' +
                        image.screenshot_token +
                        '/' +
                        $route.params.id +
                        '/' +
                        image.project_id +
                        '/' +
                        selectedMember +
                        '/' +
                        image.filename
                      "
                      data-disableScrolling="true"
                      data-lightbox="image-1"
                      v-else
                    >
                      <div
                        :class="`screenshot-${item.index}`"
                        :style="{ 'background-position': image.css }"
                      ></div>
                    </a>
                    <v-tooltip top>
                      <template v-slot:activator="{ on, attrs }">
                        <div class="bottom-bar-time" v-bind="attrs" v-on="on">
                          <p class="d-flex justify-center mb-0">
                            {{ image.capture_time }}
                          </p>
                        </div>
                      </template>
                      <p class="mb-0">{{ image_info(image, "project") }}</p>
                      <p class="mb-0">{{ image_info(image, "task") }}</p>
                    </v-tooltip>
                  </v-card>
                </div>
              </td>
            </template>
          </v-data-table>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import { mapState } from "vuex";
import moment from "moment";
import { min_to_hour } from "@/assets/js/utils";
import { baseURL } from "@/assets/js/urls";
import JsonCSV from "vue-json-csv";
import JsonExcel from "vue-json-excel";
require("lightbox2/dist/js/lightbox.min");
import "lightbox2/dist/css/lightbox.min.css";
export default {
  name: "IntelligentReport",
  props: ["timesheet_intelligence_report"],
  components: {
    "download-csv": JsonCSV,
    downloadExcel: JsonExcel,
  },
  data() {
    return {
      date_2: [],
      menu_2: false,
      modal_2: false,
      teamMemberLoader: false,
      picker: "",
      date: [],
      showPicker: false,
      menu: false,
      i: 0,
      items: [
        { id: 1, title: "Today" },
        { id: 2, title: "Yesterday" },
        { id: 3, title: "Custom Date", value: "" },
      ],
      start: moment(),
      end: moment(),
      start_date: moment().format("YYYY-MM-DD"),
      end_date: moment().format("YYYY-MM-DD"),
      selectedMember: "",
      selectedWindow: "",
      active_windows: [],
      members: [],
      filteredData: [],
      active_windows_reports: [],
      dateRangeTitle: "",
      timesheet_report: false,
      expanded: [],
      singleExpand: false,
      dessertHeaders: [
        { text: "Active Window", value: "active_window" },
        { text: "Worked Time", value: "worked_time" },
        { text: "", value: "data-table-expand" },
      ],
      desserts: [
        {
          active_window: "Frozen Yogurt",
          worked_time: "1%",
        },
      ],
    };
  },
  computed: {
    ...mapState("custom", [
      "selected_company",
      "companies_list",
      "team_members",
    ]),

    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    active_windows_reports_data() {
      if (this.selectedWindow) {
        let act_wind = this.active_windows.find(
          (win) => win === this.selectedWindow
        );
        if (act_wind) {
          return this.active_windows_reports.filter(
            (item) => item.active_window === this.selectedWindow
          );
        } else {
          return this.active_windows_reports.filter(
            (item) =>
              item.active_window
                .toLowerCase()
                .indexOf(this.selectedWindow.toLowerCase()) >= 0
          );
        }
      } else {
        return this.active_windows_reports;
      }
    },

    csvExportSetup() {
      let memberName =
        this.$store.state.custom.team_members.find(
          (m) => m.id === this.selectedMember
        ).name || "";

      let name =
        this.start_date === this.end_date
          ? `Intelligence Report of ${memberName} (${this.start_date}).csv`
          : `Intelligence Report of ${memberName} (${this.start_date} - ${this.end_date}).csv`;

      return {
        data: this.active_windows_reports_data,
        name,
        labels: {
          active_window: "Active Window",
          worked_time: "Worked Time",
        },
        fields: ["active_window", "worked_time"],
      };
    },
    excelExportSetup() {
      let memberName =
        this.$store.state.custom.team_members.find(
          (m) => m.id === this.selectedMember
        ).name || "";

      let name =
        this.start_date === this.end_date
          ? `Intelligence Report of ${memberName} (${this.start_date}).xls`
          : `Intelligence Report of ${memberName} (${this.start_date} - ${this.end_date}).xls`;

      return {
        data: this.active_windows_reports_data,
        name,
        fields: {
          "Active Window": "active_window",
          "Worked Time": "worked_time",
        },
      };
    },
  },
  watch: {
    picker() {
      this.getIntelreportFromCustomDate();
    },
  },

  mounted() {
    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style-i");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtni").click();
        x.style.display = "none";
      }
    });
    this.$store.commit("custom/toggle_loader", false);
    this.checkIfCustomDate(this.date);
    this.selectedDateTeamMembers();
    if (this.timesheet_intelligence_report) {
      if (
        this.timesheet_intelligence_report.search_member !== undefined &&
        this.timesheet_intelligence_report.search_date !== undefined
      ) {
        this.timesheet_report = true;
        this.start_date = this.timesheet_intelligence_report.search_date;
        this.end_date = this.timesheet_intelligence_report.search_date;
        this.selectedMember = this.timesheet_intelligence_report.search_member;
        this.get_windows_screenshots_diary();
      }
    }
  },
  methods: {
    /*
      Fetch the lastest working members
      data according to given date
     */
    selectedDateTeamMembers() {
      let data = {
        company_id: this.$route.params.id,
        start_date: this.start_date,
        end_date: this.end_date,
      };
      this.teamMemberLoader = true;
      this.$store
        .dispatch("custom/getLastWorkingDaysMember", data)
        .then((response) => {
          this.teamMemberLoader = false;
          this.filteredData = response.data.data.team_members;
          if (this.filteredData[0].id != "all") {
            let allMember = {
              id: "all",
              name: "All Members",
            };
            this.filteredData.unshift(allMember);
          }
          this.teamMemberLoader = false;
        })
        .catch(() => {
          this.teamMemberLoader = false;
        });
    },
    /**
     * This function is resposible for
     * return base url of api
     */
    url() {
      return baseURL.API_URL;
    },
    /**
     * This function is resposible for
     * return of image
     */
    image_info(image, show) {
      // return 'Project: ' + image.project + '\n' + 'Task: ' + image.task
      if (show === "project") {
        return `Project: ${image.project}`;
      } else {
        return `Task: ${image.task}`;
      }
    },
    /**
     * This function is resposible for
     * conversion of minutes into hours
     */
    min_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hrs = time.hrs;
      let mins = time.mins;
      if (hrs <= 9) {
        hrs = "0" + time.hrs;
      }
      if (mins <= 9) {
        mins = "0" + time.mins;
      }
      return hrs + ":" + mins;
    },
    checkClick() {
      let x = document.getElementById("custom-list-style-i");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    /**
     * This function is resposible for
     * custom date selection
     */
    getIntelreportFromCustomDate() {
      this.start_date = this.picker;
      this.end_date = this.picker;
      this.get_windows_screenshots_diary(this.picker);
      this.menu = false;
      this.dateRangeTitle = moment(this.picker).format("MMM D, YYYY");
    },
    /**
     * This function is resposible for
     * custom date selection
     */
    checkIfCustomDate(date) {
      date.value =
        moment().format("MMM D, YYYY") + "-" + moment().format("MMM D, YYYY");
      this.dateRangeTitle = date.value = "Today";
      if (date.id === 1) {
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        this.dateRangeTitle = "Today";
      }
      if (date.id === 2) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      }
      if (date.id === 3) {
        this.modal_2 = true;
        // this.showPicker = true;
        this.dateRangeTitle = "Custom Date";
        // document.getElementById("range-date").click();
      } else {
        this.showPicker = false;
        this.setDates(date);
        this.get_windows_screenshots_diary(this.start_date, this.end_date);
      }
      document.getElementById("cRangei").click();
    },
    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      }
    },
    /**
     * This function is resposible for
     * getting active window screenshots
     */
    get_windows_screenshots_diary() {
      this.selectedDateTeamMembers();
      if (
        this.start_date !== "" &&
        this.end_date !== "" &&
        this.selectedMember !== ""
      ) {
        this.selectedWindow = "";
        let data = {
          start_date: this.start_date,
          end_date: this.end_date,
          member_id: this.selectedMember,
          company_id: this.$route.params.id,
          timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
        };
        this.$store
          .dispatch("custom/get_intelligence_report", data)
          .then((response) => {
            if (response.data.error === false) {
              this.active_windows_reports = [];
              this.active_windows = [];
              let data = response.data.active_windows;
              Object.keys(data).forEach((element) => {
                this.active_windows_reports.push(data[element]);
                this.active_windows.push(data[element].active_window);
              });
              this.sortArray();
              this.addIndex();
            }
            this.$store.commit("custom/toggle_loader", false);
          })
          .catch(() => {
            this.$store.commit("custom/toggle_loader", false);
          });
      } else {
        this.active_windows_reports = [];
      }
    },
    addIndex() {
      let i = 0;
      this.active_windows_reports.map((active_window) => {
        active_window.index = i;
        i = i + 1;
      });
    },
    /**
     * This function is resposible for
     * sorting of active windows
     */
    sortArray() {
      this.active_windows.sort((a, b) => {
        let x = a.toLowerCase();
        let y = b.toLowerCase();
        if (x < y) return -1;
        if (x > y) return 1;
        return 0;
      });
    },
  },
};
</script>
<style scoped>
.btn-csv-ir {
  padding-left: 17px;
  padding-bottom: 22px;
}
.error-img {
  width: 300px;
  height: 250px;
  margin-top: 30px;
}
.col-img {
  width: 100px;
  padding: 0px 1px !important;
  margin-bottom: 10px !important;
  margin-top: 16px !important;
  margin-right: 10px !important;
}
.img-area {
  border-top-right-radius: 10px !important;
  border-top-left-radius: 10px !important;
}
.calender-btn {
  width: 200px !important;
  z-index: 0.9 !important;
  align-content: right !important;
  margin-left: 29%;
}
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}
.selected-date {
  border: 1px solid #e9f1fe !important;
  margin-top: -10px !important;
  font-size: 12px !important;
  display: flex !important;
  height: 40px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
.bottom-bar-time {
  padding: 1px 3px;
  padding-bottom: 20px !important;
  overflow: hidden;
  color: #36454f !important;
  font-family: Poppins;
  font-size: 14px;
  height: 21px;
  border-radius: 3px !important;
}
.img-holder.window-listing-img-div.col-12.col-md-12.row {
  margin-left: 54px;
}
.error_text {
  font-size: larger;
  font-weight: 500;
  color: #a9bcdb;
  margin-top: 20px;
}
</style>
